.ant-layout-sider {
    // box-shadow: 0 0 8px #333;
    border-right: 1px solid #f1f1f1;
}

.logo {
    background: #fff;
    margin: 0px;
    padding: 25px;

    img {
        max-width: 100%;
    }
}