
header.main-header {
    // background-color: $headerColor;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 0 16px;
}
