table {
    background: #fff;
}

.ant-layout-sider {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
}

.ant-layout-content {
    overflow-x: auto;
}

.ant-drawer-content-wrapper {
    max-width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
    position: unset;
}

.ant-advanced-search-form {
    .ant-form-item {
        align-items: center;
    }

    .ant-form-item-label {
        width: unset !important;
        padding-bottom: 0 !important;
        padding-right: 5px !important;
    }
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 8px;
}


.ant-dropdown-menu-item-group-list {
    padding-left: 0;
    list-style-type: none;
}

.ant-btn {
    margin-right: 5px;
    margin-bottom: 5px;
}

.btns-column .ant-btn {
    margin: 0;
}

.ant-form {

    .ant-form-item {
        margin-bottom: 10px;

        .ant-form-item-label {
            line-height: 20px;
        }
    }

    legend {
        font-size: 14px;
        margin-top: 30px;
    }
}

.ant-table-content td {
    padding: 2px 5px !important;
    font-size: 12px;

    .react-json-view {
        display: inline-block;
    }
}

.ant-drawer-body .ant-tabs-tabpane {
    padding: 0 16px;
}