.widget {
    background-color: #fff;
    // margin-right: 16px;
    // margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 0 3px #ccc;
    border-radius: 3px;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    height: 100%;


    .widget-body {
        overflow-x: auto;
    }

    .widget-title {
        font-size: 16px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        margin-bottom: 8px;
        padding-bottom: 8px;
        margin-top: -8px;
        font-weight: 500;
    }
}

.table {
    width: 100%;

    td,
    th {
        padding: 3px 5px;
        white-space: nowrap;
    }

    tr:nth-child(2n) {
        background-color: #f1f1f1;
    }
}


.hover-underline:hover {
    text-decoration: underline;
}

.data-table-filters-container {
    display: flex;
    // justify-content: space-between;
    margin-bottom: 16px;
    gap: 10px 8px;
    flex-wrap: wrap;

    >button,
    >div {
        margin: 0 !important
    }

    .filter-wrapper {
        display: block;
        position: relative;

        .filter-label {
            display: block;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(240, 242, 245, 1) 51%, rgba(240, 242, 245, 1) 100%);
            font-size: 12px;
            margin-bottom: 4px;
            position: absolute;
            top: -8px;
            left: 4px;
            z-index: 10;
            padding: 0px 4px;

        }
    }
}